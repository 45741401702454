import { createSlice } from "@reduxjs/toolkit";
import { getNasabah, createNasabah, searchNasabah, getNasabahId, updateNasabah } from "../actions/nasabah";
const nasabahSlice = createSlice({
    name:'nasabah',
    initialState:{
        data:[],
        status:'waiting'
    },
    reducers:{
        clearUser(state, action){
            state.item = action.payload
            state.status = 'waiting'
        },
        updateStatus(state){
            state.status='waiting'
            // state.data = []
        },
        clear(state){
            state.status='waiting'
            state.data = []
        }
    },
    extraReducers:builder =>{
        builder.addCase(
        getNasabah.pending, (state)=>{
            state.status='loading'
        })
        .addCase(getNasabah.rejected, (state, action)=>{
            state.status='error'
        })
        .addCase(getNasabah.fulfilled,(state, action)=>{
            state.status='success'
            state.data = action.payload
        })
        .addCase(createNasabah.pending, (state)=>{
           
            state.status='loading'
        })
        .addCase(createNasabah.rejected, (state)=>{
            state.status = 'error'
        })
        .addCase(createNasabah.fulfilled, (state, action)=>{
            console.log('okokokokok')
            console.log(action.payload)
            state.data = [action.payload]
            state.status='success'
        })
        .addCase(searchNasabah.pending,(state)=>{
            state.status='loading'
        })
        .addCase(searchNasabah.rejected, (state)=>{
            state.status='error'
        })
        .addCase(searchNasabah.fulfilled,(state, action)=>{
            state.status='success'
            state.data = action.payload
        })
        .addCase(getNasabahId.pending,(state)=>{
            state.status='pending'
        })
        .addCase(getNasabahId.rejected,(state)=>{
            state.status='error'
        })
        .addCase(getNasabahId.fulfilled,(state, action)=>{
            state.status='success'
            state.data = action.payload
        })
        .addCase(updateNasabah.pending, (state)=>{
            state.status = 'pending'
        })
        .addCase(updateNasabah.rejected, (state)=>{
            state.status = 'error'
        })
        .addCase(updateNasabah.fulfilled, (state)=>{
            state.status = 'success'
        })
        
    }
})

export const nasabahAction = nasabahSlice.actions
export default nasabahSlice