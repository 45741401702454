import React, { useState } from "react";
import TabunnganProses from "./TabunganProses";
import Mutasi from "./Mutasi";

function Tabungan(props) {
  const [mutasiActive, setMutasiActive] = useState(false);
  const [tabunganActive, setTabunganActive] = useState(true);

  const prosesTabMutasi = () => {
    setMutasiActive(true);
    setTabunganActive(false);
  };

  const prosesTabTabungan = () => {
    setMutasiActive(false);
    setTabunganActive(true);
  };

  return (
    <div className="bg-white m-5 p-4">
      <div className="text-sm font-medium text-center border-b border-gray-200 mb-5">
        <ul className="flex flex-wrap -mb-px">
          <li className="mr-2">
            <button
              className={`${
                tabunganActive
                  ? "text-blue-500 border-blue-500 "
                  : "text-gray-500 hover:text-gray-600 hover:border-gray-300"
              }inline-block p-2 border-b-2  border-transparent rounded-t-lg`}
              onClick={prosesTabTabungan}
            >
              Tabungan
            </button>
            <button
              className={`${
                mutasiActive
                  ? "text-blue-500 border-blue-500 "
                  : "text-gray-500 hover:text-gray-600 hover:border-gray-300"
              }inline-block p-2 border-b-2  border-transparent rounded-t-lg`}
              onClick={prosesTabMutasi}
            >
              Mutasi
            </button>
          </li>
        </ul>
      </div>
      {tabunganActive ? (
        <TabunnganProses
          showDialog={props.showDialog}
          dialog={props.dialog}
          hiddenDialog={props.hiddenDialog}
        />
      ) : (
        <Mutasi />
      )}
    </div>
  );
}

export default Tabungan;
