import {log} from '../../api/log'
import {getUSerReport} from '../../api/user'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getReport = createAsyncThunk(
    "log/getReport",
    async(data, {rejectWithValue})=>{
        try{
            
            const res = await log(data)
            if(res.status===200){
                console.log(res.data)
                console.log('________')
                return res.data
            }
        }catch(e){
            return rejectWithValue('uuups somethinw wrong....')
        }
    }
)

export const getUser = createAsyncThunk(
    'log/getUserReport',
    async(_, {rejectWithValue})=>{
        try{
            const res = await getUSerReport()
            if(res.status===200){
                return res.data
            }
        }catch(_){
            return rejectWithValue('uups something wrong...')
        }
    }
)