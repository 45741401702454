import { configureStore } from "@reduxjs/toolkit";
import nasabahSlice from  './slice/nasabah';
import tabunganSlice from "./slice/transaksi";
import userSlice from "./slice/user";
import dashboardSlice from "./slice/dashboard";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import reportSlice from "./slice/report";

const persistConfig = {
    key:'root',
    storage
}

export const store = configureStore({
    reducer: {
        user:persistReducer(persistConfig, userSlice.reducer),
        nasabah:nasabahSlice.reducer,
        tabungan:tabunganSlice.reducer,
        dashboard:dashboardSlice.reducer,
        report:reportSlice.reducer
       
    },
    middleware:[thunk]
})

export const persistor = persistStore(store)
