import React from "react";

function Table({ head, children }) {
  return (
    <div className="overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {head.map((d, i) => (
              <th scope="col" className="py-3 px-6" key={i}>
                {d.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {children}
        </tbody>
      </table>
    </div>
  );
}

export default Table;
