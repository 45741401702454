import { createSlice } from "@reduxjs/toolkit";
import {getDashboard} from '../actions/dashboard';


const dashboardSlice = createSlice({
    name:'dashboard',
    initialState: {
        data:{
            totalTabungan:0,
            totalNasabah:0,
            pendapatan:{
                date:[],
                tabung:[],
                tarik:[]
            }
        },
        loading:false,
        failed:false,
        tableGenerate:false
    },
    //reducers not function againt
    reducers:{
        updateTableStatus(state){
            state.tableGenerate = true
        },clearState(state){
            state.tableGenerate=false
        }
    },
    extraReducers:builder=>{
        builder.addCase(
            getDashboard.pending, (state)=>{
                state.status=true
            })
            .addCase(
            getDashboard.fulfilled, (state, action)=>{
                state.loading=false
                state.data = action.payload
            })
            .addCase(
                getDashboard.rejected, (state)=>{
                    state.loading=false
                    state.failed=true
                }
            )
    }
})

export const dashboardAction = dashboardSlice.actions
export default dashboardSlice