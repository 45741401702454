import http from '.'

export const search = (key)=>{
    return http.get(`/nasabah/search/${key}`)
}

export const nasabahGetId = (uuid)=>{
    return http.get(`/nasabah/${uuid}`)
}

export const updateNasabah = (uuid, data)=>{
    return http.put(`/nasabah/${uuid}`, data)
}