import {
  login,
  logout,
  getUser,
  createUser,
  updateStatusAdmin,
  updatePassword,
} from "../../api/user";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const userLogin = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const res = await login(data);
      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      return rejectWithValue("login error");
    }
  }
);

export const generateUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
     await createUser({
        username: data.username,
        nama: data.nama,
        password: data.password,
        admin: data.admin,
      });
      
    } catch (e) {
      console.log(e);
      return rejectWithValue("something wrong...");
    }
  }
);

export const updatePassword_ = createAsyncThunk(
  "user/updatePassword",
  async (data, { rejectWithValue }) => {
    try {
      await updatePassword({
        password: data.password,
        newPassword: data.newPassword,
        comparePassword: data.confrimPassword,
      });
      return {statusPwd:true, statusCompare:true, msg:'password compare wrong'}
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "user/updateAdmin",
  async (uuid, { rejectWithValue }) => {
    try {
      await updateStatusAdmin(uuid);
    } catch (_) {
      return rejectWithValue("uuups");
    }
  }
);

export const userLogout = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      await logout();
    } catch (_) {
      return rejectWithValue("logout error");
    }
  }
);

export const getUserList = createAsyncThunk(
  "user/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUser();
      if (res.status === 200) {
        return res.data;
      }
    } catch (_) {
      return rejectWithValue("getUser Error");
    }
  }
);
