import moment from "moment-timezone";


export const formatRupiah = (angka, prefix) => {
  let number_string = angka.toString().replace(/[^,\d]/g, "")
    let split = number_string.split(",")
    let sisa = split[0].length % 3
    let rupiah = split[0].substr(0, sisa)
    let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
  return prefix === undefined ? rupiah : rupiah ? "" + rupiah : "";
};

export const convertToRupih = (angka)=>{
    let number_ = angka.toString().replaceAll('.','')
   
    return parseInt(number_) 

}

export const timeShow = (dateTime)=>{
  return moment(dateTime).tz('Asia/Makassar').format('DD-MM-YYYY HH:mm')
}

export const timeToUtc = (dateTime, start)=>{
  let date_ = moment(dateTime).tz('Asia/Makassar').format('YYYY-MM-DD')
  if(start){
    
    return moment(`${date_} 00:00:00`).tz('Asia/Makassar').utc()
  }else{
    return moment(`${date_} 23:59:59`).tz('Asia/Makassar').utc()
  } 
}
