import React, { useEffect } from "react";
import Card from "../components/Card";
import TextField from "../components/TextField";
import Button from "../components/Button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Radio from "../components/Radio";
import { useDispatch, useSelector } from "react-redux";
import { createNasabah } from "../store/actions/nasabah";
import { nasabahAction } from "../store/slice/nasabah";
import { useNavigate } from "react-router-dom";
import Dialog from "../components/Dialog";

const schema = yup.object({
  nik: yup
    .number()
    .typeError("hanya boleh angka saja!")
    .required("tidak boleh kosong")
    .min(3, "min 3 karakter"),
  nama: yup.string().required("tidak boleh kosong").min(3, "min 3 karakter"),
  alamat: yup
    .string()
    .required("tidak boleh kosong")
    .min(3, "min 3 karakter")
    .max(255, "max 255 karakter"),
  tglLahir: yup.date().required().typeError("harus format Tanggal"),
  kelamin: yup
    .string()
    .required("harus diisi!")
    .transform((value) => (value == null ? "" : value)),
  noTelpon: yup
    .string()
    .required("tidak boleh kosong!")
    .min(8, "min 8 karakter")
    .typeError("haruns angka!"),
  namaIbuKandung: yup
    .string()
    .required("tidak boleh kosong!")
    .min(3, "min 3 karakter"),
  statusPernikahan: yup
    .string()
    .required("tidak boleh kosong!")
    .transform((value) => (value == null ? "" : value)),
  pekerjaan: yup.string().required("tidak boleh kosong!"),
  penghasilan: yup.number().typeError("hanya boleh angka saja!").required(),
  keluargaSerumahNama: yup.string().required("tidak boleh kosong!"),
  keluargaSerumahPekerjaan: yup.string().required("tidak boleh kosong!"),
  keluargaBedaRumahNama: yup.string().required('tidak boleh kosong!'),
  keluargaBedaRumahNoTelpon: yup.string().required("tidak boleh kosong!"),
});

const kelamin = [
  {
    label: "Pria",
    value: "L",
  },
  { label: "Wanita", value: "W" },
];

const statusPernikahan_ = [
  { label: "Menikah", value: "Menikah" },
  { label: "Bujang/Lajang", value: "Bujang/Lajang" },
  { label: "Duda/Janda", value: "Duda/Janda" },
];
const listPekerjaan = [
  "Belum/ Tidak Bekerja",
  "Mengurus Rumah Tangga",
  "Pelajar/ Mahasiswa",
  "Pensiunan",
  "Pewagai Negeri Sipil",
  "Tentara Nasional Indonesia",
  "Kepolisisan RI",
  "Perdagangan",
  "Petani/ Pekebun",
  "Peternak",
  "Nelayan/ Perikanan",
  "Industri",
  "Konstruksi",
  "Transportasi",
  "Karyawan Swasta",
  "Karyawan BUMN",
  "Karyawan BUMD",
  "Karyawan Honorer",
  "Buruh Harian Lepas",
  "Buruh Tani/ Perkebunan",
  "Buruh Nelayan/ Perikanan",
  "Buruh Peternakan",
  "Pembantu Rumah Tangga",
  "Tukang Cukur",
  "Tukang Listrik",
  "Tukang Batu",
  "Tukang Kayu",
  "Tukang Sol Sepatu",
  "Tukang Las/ Pandai Besi",
  "Tukang Jahit",
  "Penata Rias",
  "Penata Busana",
  "Penata Rambut",
  "Mekanik",
  "Seniman",
  "Tabib",
  "Perancang Busana",
  "Penterjemah",
  "Imam Masjid",
  "Wartawan",
  "Ustadz/ Mubaligh",
  "Juru Masak",
  "Promotor Acara",
  "Anggota DPR-RI",
  "Anggota DPD",
  "Anggota BPK",
  "Anggota Mahkamah Konstitusi",
  "Anggota Kabinet/ Kementerian",
  "Duta Besar",
  "Gubernur",
  "Wakil Gubernur",
  "Bupati",
  "Wakil Bupati",
  "Walikota",
  "Wakil Walikota",
  "Anggota DPRD Provinsi",
  "Anggota DPRD Kabupaten/ Kota",
  "Dosen",
  "Guru",
  "Pilot",
  "Pengacara",
  "Notaris",
  "Arsitek",
  "Akuntan",
  "Konsultan",
  "Dokter",
  "Bidan",
  "Perawat",
  "Apoteker",
  "Psikiater/ Psikolog",
  "Penyiar Televisi",
  "Penyiar Radio",
  "Pelaut",
  "Peneliti",
  "Sopir",
  "Pialang",
  "Paranormal",
  "Pedagang",
  "Perangkat Desa",
  "Kepala Desa",
  "Wiraswasta",
];

function Register(props) {
  const { status, data } = useSelector((state) => state.nasabah);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const hiddenDialog_ = ()=>{
    dispatch(nasabahAction.clear())
    navigate('/search')
    props.hiddenDialog()
  }

  const onSubmit = (data) => {
    dispatch(createNasabah(data));
    console.log(data);
    console.log('-----')
  };

  const reset = () => {
    
    methods.reset();
    methods.clearErrors();
  };

  useEffect(() => {
    if (status === "success") {
      dispatch(nasabahAction.updateStatus());
      methods.clearErrors();
      methods.reset();
      props.showDialog()
    }
  }, [status, dispatch, methods, props]);
  return (
    <Card title="Register">
      {/* {JSON.stringify(data)} */}
      <Dialog dialog={props.dialog} hiddenDialog={hiddenDialog_} message={`Data berhasil disimpan. No Rek: ${data[0]?.noRek}`}/>
      <form onSubmit={methods.handleSubmit(onSubmit)} onReset={reset}>
        <TextField
          width="md:w-1/2"
          label="NIK"
          methods={methods}
          name_="nik"
          typeField="number"
        />
        <TextField
          width="md:w-1/2"
          label="Nama"
          methods={methods}
          name_="nama"
          typeField="text"
        />
        <TextField
          width="md:w-1/2"
          label="Alamat"
          methods={methods}
          name_="alamat"
          typeField="text"
        />
        <TextField
          width="md:w-1/2"
          label="Tanggal Lahir"
          methods={methods}
          name_="tglLahir"
          typeField="date"
        />
        <Radio
          title="Jenis Kelamin"
          deco={kelamin}
          name_="kelamin"
          methods={methods}
          value_={"L"}
        />
        <TextField
          width="md:w-1/2"
          label="No Telpon"
          methods={methods}
          name_="noTelpon"
          typeField="number"
        />
        <TextField
          width="md:w-1/2"
          label="Nama Ibu Kandung"
          methods={methods}
          name_="namaIbuKandung"
          typeField="text"
        />
        <Radio
          title="Status Pernikahan"
          name_="statusPernikahan"
          methods={methods}
          deco={statusPernikahan_}
          value="Menikah"
        />
        <div className="flex flex-col mb-4">
          <label className="font-medium mb-2">Pekerjaan</label>
          <div className="w-1/2">
            <select
              className={`px-3 py-3 w-full border border-gray-300 rounded-lg outline-none ${
                methods.formState.errors["pekerjaan"]
                  ? "focus:border-red-500"
                  : "focus:border-pink-200"
              }`}
              {...methods.register("pekerjaan")}
            >
              {listPekerjaan.map((d, i) => (
                <option value={d} key={i}>
                  {d}
                </option>
              ))}
            </select>
          </div>
        </div>
        <TextField
          width="md:w-1/2"
          label="Penghasilan"
          methods={methods}
          name_="penghasilan"
          typeField="number"
        />
        <TextField
          width="md:w-1/2"
          label="Keluarga Serumah"
          methods={methods}
          name_="keluargaSerumahNama"
          typeField="text"
        />
        <div className="flex flex-col mb-4">
          <label className="font-medium mb-2">Pekerjaan</label>
          <div className="w-1/2">
            <select
              className={`px-3 py-3 w-full border border-gray-300 rounded-lg outline-none ${
                methods.formState.errors["keluargaSerumahPekerjaan"]
                  ? "focus:border-red-500"
                  : "focus:border-pink-200"
              }`}
              {...methods.register("keluargaSerumahPekerjaan")}
            >
              {listPekerjaan.map((d, i) => (
                <option value={d} key={i}>
                  {d}
                </option>
              ))}
            </select>
          </div>
        </div>
        <TextField
          width="md:w-1/2"
          label="Keluarga Beda Rumah"
          methods={methods}
          name_="keluargaBedaRumahNama"
          typeField="text"
        />
        <TextField
          width="md:w-1/2"
          label="Keluarga Beda Rumah Nomor Telpon"
          methods={methods}
          name_="keluargaBedaRumahNoTelpon"
          typeField="number"
        />

        <div className="flex justify-end">
          <Button
            name="Simpan"
            type_="submit"
            color="bg-blue-500 hover:bg-blue-700"
          />
          <Button
            name="Batal"
            type_="reset"
            color="bg-red-500 hover:bg-red-700"
          />
        </div>
      </form>
    </Card>
  );
}

export default Register;
