import React, { useEffect } from "react";
import Card from "../components/Card";
import { timeShow, formatRupiah } from "../components/util";
import Table from "../components/Table";
import { getTabunganId } from "../store/actions/transaksi";
// import { tabunganAction } from '../store/slice/transaksi'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const head = [
  { title: "Tanggal" },
  { title: "Ref" },
  { title: "D" },
  { title: "K" },
];

function Log() {
  const { data } = useSelector((state) => state.tabungan);
  const { uuid } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTabunganId(uuid));
  }, [dispatch, uuid]);

  return (
    <Card title="History Setoran">
      <Table head={head}>
        {data.tabungans.map((d, i) => (
          <tr className="bg-white border-b hover:bg-gray-50" key={i}>
            <td className="py-4 px-6">{timeShow(d.updatedAt)}</td>
            <td className="py-4 px-6">{d.ref}</td>
            <td className="py-4 px-6 text-right">
              {d.status === "D" ? formatRupiah(d.jumlah) : "-"}
            </td>
            <td className="py-4 px-6 text-right">
              {d.status === "K" ? formatRupiah(d.jumlah) : "-"}
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={3} className="text-base font-medium text-right">
            Umroh
          </td>
          <td className="text-lg font-medium text-right">
            {
              formatRupiah(data.totalTabungans[data.totalTabungans.findIndex(p=>p.type==='umr')].total)
            }
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-base font-medium text-right">
            Kurban
          </td>
          <td className="text-lg font-medium text-right">
          {formatRupiah(data.totalTabungans[data.totalTabungans.findIndex(p=>p.type==='kbr')].total)}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="text-base font-medium text-right">Umum</td>
          <td className="text-lg font-medium text-right">
          {formatRupiah(data.totalTabungans[data.totalTabungans.findIndex(p=>p.type==='umm')].total)}
          </td>
        </tr>

        <tr>
          <td colSpan={3}>
            <div className="text-base font-medium text-right">Total</div>
          </td>
          <td className="text-lg font-medium text-right">
            {formatRupiah(
              data.totalTabungans[0].total +
                data.totalTabungans[1].total +
                data.totalTabungans[2].total
            )}
          </td>
        </tr>
      </Table>
    </Card>
  );
}

export default Log;
