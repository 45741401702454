import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form"
import TextField from "./TextField";
import { useDispatch, useSelector } from "react-redux";
import { generateUser, getUserList } from "../store/actions/user";
import { userAction } from "../store/slice/user";

const schema = yup.object({
    nama:yup.string().required('tidak boleh kosong').min(3, 'min 3 karakter'),
    username:yup.string().required('tidak boleh kosong').min(3, 'min 3 karakter'),
    password:yup.string().required('tidak boleh kosong').min(8, 'min 8 karakter'),
    confrimPassword:yup.string().oneOf([yup.ref('password'), null], "Password harus sama!").required('tidak boleh kosong!'),
    admin:yup.bool().default(false)
})


function AddUser({ dialog, hiddenDialog }) {
  const methods = useForm({
    resolver:yupResolver(schema)
  })
  const dispatch = useDispatch()
  const {userStatus} = useSelector((state)=>state.user)


  const onSubmit = async (data)=>{
    await dispatch(generateUser(data))
    await dispatch(getUserList())
    methods.reset()
    methods.clearErrors()

  }
  const reset = ()=>{
    methods.reset()
    methods.clearErrors()
    hiddenDialog()
  }
  return (
    <div className={`${dialog ? "" : "hidden"}`}>
      <div className="justify-center items-center flex overflow-x-hidden z-50 overflow-y-auto fixed inset-0 outline-none focus:outline-none">
        <div className="relative md:w-1/2 w-full my-6">
            <div className="flex flex-col bg-white p-5 px-10 rounded">
                <div className="py-2 font-bold text-xl mb-3">
                    Register User
                </div>
                <form onSubmit={methods.handleSubmit(onSubmit)} onReset={reset}>
                    <TextField typeField="text" methods={methods} label="Nama" width="w-full" name_="nama"/>
                    <TextField typeField="text" methods={methods} label="Username" width="w-full" name_="username"/>
                    <TextField typeField="password" methods={methods} label="Password" width="w-full" name_="password"/>
                    <TextField typeField="password" methods={methods} label="Confrim Password" width="w-full" name_="confrimPassword"/>
                    <div className="flex flex-row space-x-2">
                        <input type="checkbox" {...methods.register('admin')}/> <label>Admin</label>
                    </div>
                    <div className="flex flex-row justify-end space-x-2">
                        <button type="submit" className="text-white bg-blue-500 p-2 rounded">Simpan</button>
                        <button type="reset" className="text-white bg-red-500 p-2 rounded">Batal</button>
                    </div>
                </form>
            </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black" ></div>
    </div>
  );
}

export default AddUser;
