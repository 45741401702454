import { dashboard } from "../../api/dashboard";
import { createAsyncThunk } from "@reduxjs/toolkit";


export const getDashboard = createAsyncThunk(
    "dashboard/getDashboard",
    async(_, {rejectWithValue})=>{
        try{
            const res = await dashboard()
            if(res.status===200){
                return res.data
            }
        }catch(_){
            return rejectWithValue('uups something wrong...')
        }
    }
)