import http from '.'

export const login = (data)=>{
    return http.post(`/user/login`, data)
}

export const logout = ()=>{
    return http.get(`/user/logout`)
}

export const getUser = ()=>{
    return http.get(`/user/user`)
}

export const createUser = (data)=>{
    return http.post(`/user`, data)
}

export const getUSerReport = ()=>{
    return http.get(`/user/all`)
}

export const updateStatusAdmin = (uuid)=>{
    return http.get(`/user/update/status/${uuid}`)
}

export const updatePassword = (data)=>{
    return http.post(`/user/update/password`, data)
}