import { createSlice } from "@reduxjs/toolkit";
import { getTabunganId, createTabungan, mutasi } from "../actions/transaksi";

const tabunganSlice = createSlice({
    name:'tabungan',
    initialState:{
        data:{
            noRek:'',
            nik:'',
            nama:'',
            tglLahir:'',
            tabungans:[],
            totalTabungans:[
                {
                    total:0,
                    type:"umm"
                },
                {
                    total:0,
                    type:"umr"
                },
                {
                    total:0,
                    type:"kbr"
                }
            ]
        },
        status:'waiting',
        redirect:false
        
    },
    reducers:{
        updateStatus(state){
            state.status = 'waiting'
        },
        updateRedirect(state, action){
            state.redirect = action.payload
        }
    },
    extraReducers:builder =>{
        builder.addCase(getTabunganId.pending, (state)=>{
            state.status='loading'
        })
        .addCase(getTabunganId.rejected,(state)=>{
            state.status='error'
        })
        .addCase(getTabunganId.fulfilled, (state, action)=>{
            state.status='success'
            state.data = action.payload
        })
        .addCase(createTabungan.pending, (state)=>{
            state.status='loading'
        })
        .addCase(createTabungan.rejected,(state, action)=>{
            if(action.payload===400){
                state.status='saldo tidak cukup'
            }else{
                state.status='error'
            }
        })
        .addCase(createTabungan.fulfilled, (state)=>{
            state.status = 'success'
            state.redirect = true
        }).addCase(mutasi.pending, (state)=>{
            state.status='loading'
        }).addCase(mutasi.rejected,(state, action)=>{
            state.status = action.payload
        }).addCase(mutasi.fulfilled,(state)=>{
            state.status='success'
            state.redirect=true
        })
    }
})

export const tabunganAction = tabunganSlice.actions
export default tabunganSlice