import { getTabungan, postTabungan, postMutasi } from "../../api/tabungan";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getTabunganId = createAsyncThunk(
  "tabungan/getTabunganId",
  async (uuid, { rejectWithValue }) => {
    try {
      const res = await getTabungan(uuid);
      if (res.status === 200) {
        return res.data;
      }
    } catch (e) {
      return rejectWithValue("uuups something wrong");
    }
  }
);

export const createTabungan = createAsyncThunk(
  "tabungan/createTabungan",
  async (data, { rejectWithValue }) => {
    try {
      await postTabungan(data.data, data.uuid);
      
    } catch (e) {
      console.log(e)
      return rejectWithValue(400)
      
      // if (e.response.status === 400) {
      //   return rejectWithValue(400);
      // } else {
      //   return rejectWithValue("uuuups something wrong");
      // }
    }
  }
);


export const mutasi = createAsyncThunk(
  'tabungan/mutasiTabungan',
  async(data, {rejectWithValue})=>{
    try{
      await postMutasi(data.data, data.uuid)
    }catch(e){
      if(e.response.status===400){
        return rejectWithValue('saldo tidak cukup')
      }
      else{
        return rejectWithValue('uups')
      }
    }
  }
)
