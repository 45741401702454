import { createSlice } from "@reduxjs/toolkit";
import { getReport, getUser } from "../actions/report";

const reportSlice = createSlice({
    name:'report',
    initialState:{
        dataReport:[],
        statusLoading:false,
        userReport:[{
            username:"all",
            nama:"Semua"
        }]
    },
    reducers:{
        clearDataReport(state){
            state.dataReport=[]
        }
    },
    extraReducers:builder=>{
        builder.addCase(
            getReport.pending,(state)=>{
                state.statusLoading=true
            }
        ).addCase(getReport.fulfilled, (state, action)=>{
            state.statusLoading=false
            state.dataReport = action.payload
        }).addCase(getReport.rejected,(state)=>{
            state.statusLoading = false
        }).addCase(getUser.pending,(state)=>{
            state.statusLoading=true
        }).addCase(getUser.fulfilled,(state, action)=>{
            state.statusLoading=false
            let defaultUser = [{username:'all', nama:'Semua'}]
            let marged = defaultUser.concat(action.payload) 
            state.userReport = marged
        }).addCase(getUser.rejected,(state)=>{
            state.statusLoading=false
        })
    }
})

export const reportAction =  reportSlice.actions
export default reportSlice