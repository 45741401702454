// import { Input } from 'postcss'
import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { searchNasabah } from "../store/actions/nasabah";
import { nasabahAction } from "../store/slice/nasabah";
import Table from "../components/Table";
import { useNavigate } from "react-router-dom";
import Dialog from "../components/Dialog";

const head = [
  { title: "No Rek" },
  { title: "Nama" },
  { title: "Alamat" },
  { title: "No Telpon" },
  { title: "#" },
];

function Search(props) {
  const { status, data } = useSelector((state) => state.nasabah);
  const dispatch = useDispatch();
  const [nasabah, setNasabah] = useState([]);
  const navigate = useNavigate();
  const hiddenDialog_ = () => {
    console.log("apa itu dialog");
    props.hiddenDialog();
  };
  useEffect(() => {
    dispatch(nasabahAction.clear());
  }, [dispatch]);
  useEffect(() => {
    if (status === "success") {
      dispatch(nasabahAction.updateStatus());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (data.lenth === 0) {
      setNasabah([]);
    }
    setNasabah(data);
  }, [setNasabah, data]);

  const search = async (e) => {
    if (e.target.value.length >= 3) {
      dispatch(searchNasabah(e.target.value));
    } else if (e.target.value.length === 0) {
      setNasabah([]);
    }
  };

  const log = (uuid) => {
    navigate(`/nasabah/log/${uuid}`);
  };
  const setoran = (uuid) => {
    navigate(`/tabungan/${uuid}`);
  };

  const edit = (uuid) => {
    navigate(`/nasabah/edit/${uuid}`);
  };

  return (
    <Card title="Cari">
      <Dialog dialog={props.dialog} hiddenDialog={hiddenDialog_} />
      <input
        className="px-3 py-3 mb-10 md:w-1/4 w-full  border border-gray-300 rounded-lg outline-none"
        placeholder="Rek/Nama"
        onChange={(e) => search(e)}
      />
      {nasabah.length !== 0 ? (
        <Table head={head}>
          {nasabah.map((d, i) => (
            <tr className="bg-white border-b hover:bg-gray-50" key={i}>
              <td className="py-4 px-6">{d.noRek}</td>
              <td className="py-4 px-6">{d.nama.toUpperCase()}</td>
              <td className="py-4 px-6">{d.alamat.toUpperCase()}</td>
              <td className="py-4 px-6">{d.noTelpon}</td>
              <td className="py-4 px-6">
                <button
                  className="rounded-full p-3 m-1 bg-blue-600 hover:bg-blue-700"
                  onClick={() => setoran(d.uuid)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                  </svg>
                </button>
                <button
                  className="rounded-full p-3 m-1 bg-pink-600 hover:bg-pink-700"
                  onClick={() => log(d.uuid)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </button>
                <button
                  className="rounded-full p-3 m-1 bg-green-600 hover:bg-green-700"
                  onClick={() => edit(d.uuid)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </button>
                <button onClick={props.showDialog}>Tampil</button>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        ""
      )}
    </Card>
  );
}

export default Search;
