import React from 'react'
import { NavLink } from 'react-router-dom'
import ReportIcon from './icon/Report'

const active = 'p-2 bg-pink-100 cursor-auto font-bold text-gray-500 text-pink-600'
const notActive = 'p-2 hover:bg-pink-100 cursor-auto font-bold text-gray-500 hover:text-pink-600'



function Sidebar({status}) {
  return (
    <div
    className={` ${
     
      status?" transition-all min-h-screen ease-out duration-500 flex flex-col bg-gray-50 justify-between w-64 py-4 px-2":"transition-all ease-in duration-500 flex flex-col bg-gray-50 justify-between w-0"
        //  "flex flex-col bg-gray-50 h-screen justify-between w-64 py-4 px-2 transition-all ease-in-out duration-500"
         
    }`}
  >
    <div className="flex flex-col items-center justify-between text-gray-600 text-3xl pb-1 ">
      <div className="pb-40 pb-8">
        <b>TSPtkU</b> 
      </div>
      <span className="h-1 bg-slate-300 rounded w-full "></span>
    </div>
    <div className="flex flex-col  flex-auto">
      <NavLink to='/dashboard' className={({isActive})=> isActive ? active : notActive}>
        <div className="flex flex-row space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-pink-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
            />
          </svg>
          <h4>
            Dashboard
          </h4>
        </div>
      </NavLink>
      <NavLink to='/register' className={({isActive})=> isActive ? active : notActive}>
        <div className="flex flex-row space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-pink-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        
          <h4>
            Register Nasabah
          </h4>
        </div>
      </NavLink>
      <NavLink to='/search' className={({isActive})=> isActive ? active : notActive}>
        <div className="flex flex-row space-x-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-pink-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5"
            />
          </svg>
          <h4>
            Cari
          </h4>
        </div>
      </NavLink>
      <NavLink to='/report' className={({isActive})=> isActive ? active : notActive}>
        <div className="flex flex-row space-x-3">
          <ReportIcon/>
          <h4>
            Laporan
          </h4>
        </div>
      </NavLink>
    </div>
    {/* <div className="flex flex-col">
      <button className="rounded-full bg-pink-500 py-2 text-chite text-lg">
        Logout
      </button>
    </div> */}
  </div>
  )
}

export default Sidebar