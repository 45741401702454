import React, { useEffect } from 'react'
import Card from '../components/Card'
import { useDispatch, useSelector } from 'react-redux'
import { getUserList, updateAdmin } from '../store/actions/user'
import Table from '../components/Table'
import AddUser from '../components/AddUser'
// import { getUser } from '../api/user'
// import { userAction } from '../store/slice/user'
const head = [
    {title:"No"},
    {title:"Nama"},
    {title:"Username"},
    {title:"Admin"}
]

function Users(props) {
  const dispatch = useDispatch()
  const {listUser, userStatus} = useSelector((state)=>state.user)
  useEffect(()=>{
    dispatch(getUserList())
   
  }, [dispatch])
  const showDialog = ()=>{
    props.showDialog()
  }

  const prosesAdmin = async (uuid)=>{
    // await dispatch(userAction.updateData())
    await dispatch(updateAdmin(uuid))
    await dispatch(getUserList())
    
  }
  return (
    <Card title="List User">
      {userStatus}
      <AddUser dialog={props.dialog} hiddenDialog={props.hiddenDialog}/>
      <div className='flex flex-col'>
        <div className='flex flex-row  justify-end mr-1 mb-3'>
          <button className='btn bg-blue-500 rounded p-2 text-white' onClick={showDialog}>Tambah User</button>
        </div>
        <div className='w-full'>
      <Table head={head}>
        {
      
        listUser.map((d,i)=>(
          <tr className="bg-white border-b hover:bg-gray-50" key={i}>
             <td className="py-4 px-6">
                 {i+1}
             </td>
             <td className="py-4 px-6">
                 {d.nama}
             </td>
             <td className="py-4 px-6">
                 {d.username}
             </td>

             <td className="py-4 px-6 content-center">
                <button className={`${d.admin ? 'bg-blue-500':'bg-red-500'} text-white px-2 rounded text-xs font-bold`} onClick={()=>prosesAdmin(d.uuid)}>{d.admin ? 'YA':'TIDAK'}</button>
             </td>
          </tr>
     ))
        }
      </Table>
      </div>
      </div>
    </Card>

  )
}

export default Users