import React from 'react'
import Card from './Card'


function Dialog({dialog, hiddenDialog, message}) {
    
  return (
    <div className={`${dialog ? "" : "hidden"}`}>
        <div className="justify-center items-center flex overflow-x-hidden z-50 overflow-y-auto fixed inset-0 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            <Card title="Information">{message} <button className="bg-blue-500 rounded p-2 text-white" onClick={hiddenDialog}>OK</button></Card>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black" ></div>
      </div>
  )
}

export default Dialog