import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getTabunganId } from '../store/actions/transaksi'
import { convertToRupih, formatRupiah } from '../components/util'
import { mutasi } from '../store/actions/transaksi'
import { tabunganAction } from '../store/slice/transaksi'

function Mutasi() {
  const {data, status, redirect} = useSelector((state)=>state.tabungan)
  const {uuid} = useParams()
  const dispatch = useDispatch()
  const [rupiahError, setRupiahError] = useState(false)
  const [rupiahDisplay, setRupiahDisplay] = useState("")
  const [statusTabungan, setStatusTabungan] = useState(false)
  const [typeOne, setTypeOne] = useState('umr')
  const [typeTwo, setTypeTwo] = useState('umr')
  const navigate = useNavigate()
  const [data_, setData_] = useState({
    data:{
        from:"",
        to:"",
        jumlah:0
    },
    uuid:uuid
  })



  useEffect(()=>{
    dispatch(getTabunganId(uuid))
  },[dispatch, uuid])

  const rupiah = (e)=>{
    e.preventDefault();
    setRupiahDisplay(formatRupiah(e.target.value, ','))
  }

  useEffect(()=>{
    if(redirect){
        const processRedirerct = async ()=>{
            if(status==='success'){
                await dispatch(tabunganAction.updateRedirect(false))
                await dispatch(tabunganAction.updateStatus())
                navigate(`/cetak/${uuid}`)
            }else{
                await dispatch(tabunganAction.updateRedirect(false))
            }
        }
        processRedirerct()
    }
    
  },[dispatch, navigate, redirect, status, uuid])

  const prosesToApi = async()=>{
    let copyData_ = {...data_}
    copyData_.data.from = typeOne
    copyData_.data.to = typeTwo
    copyData_.data.jumlah = convertToRupih(rupiahDisplay||"0")
    setData_ ((data_)=>({
        ...copyData_
    }))
    await dispatch(mutasi(data_))
    await dispatch(tabunganAction.updateRedirect(true))
  }

  const simpan = ()=>{
    let rupiahStatus_ = false
    let statusTabungan_ = false
    if(rupiahDisplay.length>0){
        setRupiahError(false)
        rupiahStatus_ = true

    }else{
        setRupiahError(true)
        
    }

    if(typeTwo===typeOne){
        setStatusTabungan(true)
    }else{
        setStatusTabungan(false)
        statusTabungan_=true
    }
    if(statusTabungan_ && rupiahStatus_){
        prosesToApi()
    }

  }



  return (
   <Card title="Mutasi">
    <div className="font-bold mb-2 flex flex-row space-x-4 mb-7">
            {data.totalTabungans.map((d,i)=>(
                d.type==='umr'?<p key={i}>Umroh:{` ${formatRupiah(d.total)}`}</p>:d.type==='kbr'?<p key={i}>Qurban:{` ${formatRupiah(d.total)}`}</p>:<p key={i}>Umum:{` ${formatRupiah(d.total)}`}</p>
               
            ))}
            <p>Total:{" "}
            {formatRupiah(
              data.totalTabungans[0].total +
                data.totalTabungans[1].total +
                data.totalTabungans[2].total
            )}</p>
          </div>
    <div className='flex flex-col mb-2 mt-4'>
        <label className='font-medium mb-2'>Dari</label>
        <select className='px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200' onChange={(e)=>setTypeOne(e.target.value)}>
            <option value="umr">UMROH</option>
            <option value="kbr">QURBAN</option>
            <option value="umm">UMUM</option>
        </select>
    </div>
    <div className='flex flex-col mb-2 mt-4'>
        <label className='font-medium mb-2'>Ke</label>
        <select className='px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200' onChange={(e)=>setTypeTwo(e.target.value)}>
            <option value="umr">UMROH</option>
            <option value="kbr">QURBAN</option>
            <option value="umm">UMUM</option>
        </select>
        { statusTabungan&& (
                <p className="pt-2 text-red-500">Uuups... tidak boleh sama</p>

        )}
    </div>
    <div className="flex flex-col mb-4">
            <label className="font-medium mb-2">Nominal</label>
            <div className="w-1/2">
              <input
                value={rupiahDisplay || ""}
                onChange={rupiah}
                className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200"
              />
              {rupiahError && (
                <p className="pt-2 text-red-500">Harus diisi bapack....</p>
              )}
              <button
                className="mt-4 pt-2 pb-2 pr-3 pl-3 rounded-lg bg-blue-500 hover:bg-blue-700 text-lg text-white "
                onClick={simpan}
              >
                SIMPAN
              </button>
            </div>
          </div>
   </Card>
  )
}

export default Mutasi