import http from ".";

export const nasabah=()=>{
    return http.get(`/nasabah`)
}

export const generateNasabah = (data)=>{
    return http.post(`/nasabah`, data)
}

export const getTabungan = (uuid)=>{
    return http.get(`/transaksi/${uuid}`)
}

export const postTabungan = (data, uuid)=>{
    return http.post(`/transaksi/${uuid}`, data)
}

export const postMutasi = (data, uuid)=>{
    return http.post(`/transaksi/mutasi/${uuid}`, data)
}