import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/Card";
import TextFieldV from "../components/TextFieldV";
import { getTabunganId, createTabungan } from "../store/actions/transaksi";
import { formatRupiah, convertToRupih } from "../components/util";
import { tabunganAction } from "../store/slice/transaksi";
import DialogYesNo from "../components/DialogYesNo";

function TabunganProses(props) {
  const { data, status, redirect } = useSelector((state) => state.tabungan);
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const [statusError, setStatusError] = useState(false);
  const navigate = useNavigate();

  

  const [data_, setData_] = useState({
    data: {
      jumlah: 0,
      status: "",
      type: "",
      pembayaran: "",
    },
    uuid: uuid,
  });
  const [rupiahDisplay, setRupiahDisplay] = useState("");

  // useEffect(()=>{
  //   if(statusError){
  //     if(0 >= data_.data.jumlah){
  //       setStatusError(true)
  //     }else{
  //       setStatusError(false)
  //     }
  //   }

  // },[data_.data.jumlah, setStatusError, statusError])

  useEffect(() => {
    // if (status === "waiting") {
    //    dispatch(getTabunganId(uuid));
    // }
    // console.log(data.totalTabungans)

    dispatch(getTabunganId(uuid));
  }, [dispatch, uuid, data]);

  useEffect(() => {
    if (status === "saldo tidak cukup") {
      alert(status);
    }
  }, [status, dispatch]);
  useEffect(() => {
    if (redirect) {
      // console.log('aku redirect')
      // console.log(status)
      const processRedirerct = async () => {
        if (status === "success") {
          await dispatch(tabunganAction.updateRedirect(false));
          await dispatch(tabunganAction.updateStatus());
          // console.log('aku diproses')
          navigate(`/cetak/${uuid}`);
        } else {
          await dispatch(tabunganAction.updateRedirect(false));
        }
      };
      processRedirerct();
    }
  }, [dispatch, navigate, redirect, status, uuid]);
  const rupiah = (e) => {
    e.preventDefault();
    setRupiahDisplay(formatRupiah(e.target.value, ","));
  };
  const [transaksiStatus, setTransaksiStatus] = useState("K");
  const [type, setType] = useState("umr");
  const [pembayaran, setPembayaran] = useState("tunai");
  const save = async () => {
    if (rupiahDisplay.length > 0) {
      setStatusError(false);
      props.showDialog();
    } else {
      setStatusError(true);
    }

    // console.log(data_)
    // if(data_.data.jumlah>0){
    //   setStatusError(false)

    // }else{
    //   setStatusError(true)
    // }
  };
  
  const yesProses = async () => {
    let copyData_ = { ...data_ };
    copyData_.data.jumlah = convertToRupih(rupiahDisplay || "0");
    copyData_.data.status = transaksiStatus;
    copyData_.data.type = type;
    copyData_.data.pembayaran = pembayaran;
    setData_((data_) => ({
      ...copyData_,
    }));
    await dispatch(createTabungan(data_));
    await dispatch(tabunganAction.updateRedirect(true));
    props.hiddenDialog();
  };
  const noProses = () => {
    props.hiddenDialog();
  };

  return (
      <Card title="Tabungan">
        <DialogYesNo
          dialog={props.dialog}
          yes={yesProses}
          no={noProses}
          message={"Yakin untuk mmelanjutkan proses ini?"}
        />
        {/* {status} */}
        {/* {status}
        {redirect.toString()}
        {JSON.stringify(data_)} */}
        {/* {JSON.stringify(data_)}
        
        {JSON.stringify(data)} */}

        {/**batas tabungan */}
        <div>
          <div className="font-bold mb-2 flex flex-row space-x-4 mb-7">
            {data.totalTabungans.map((d,i)=>(
                d.type==='umr'?<p key={i}>Umroh:{` ${formatRupiah(d.total)}`}</p>:d.type==='kbr'?<p key={i}>Qurban:{` ${formatRupiah(d.total)}`}</p>:<p key={i}>Umum:{` ${formatRupiah(d.total)}`}</p>
               
            ))}
            <p>Total:{" "}
            {formatRupiah(
              data.totalTabungans[0].total +
                data.totalTabungans[1].total +
                data.totalTabungans[2].total
            )}</p>
          </div>
          <TextFieldV
            label="Nomor Rekening"
            val={data.noRek}
            width="w-1/3"
            disable={true}
          />
          <TextFieldV
            label="Nama"
            val={data.nama.toUpperCase()}
            width="w-1/2"
            disable={true}
          />

          <div className="flex flex-col mb-4">
            <label className="font-medium mb-2">Status</label>
            <div className="w-1/2">
              <select
                className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200"
                onChange={(e) => setTransaksiStatus(e.target.value)}
              >
                <option value="K">Tabung</option>
                <option value="D">Tarik</option>
              </select>
            </div>
            <div className="flex flex-col mb-2 mt-4">
              <label className="font-medium mb-2">Tujuan</label>
              <div className="w-1/2">
                <select
                  className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="umr">UMROH</option>
                  <option value="kbr">QURBAN</option>
                  <option value="umm">UMUM</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col mb-2 mt-4">
              <label className="font-medium mb-2">Transaksi</label>
              <div className="w-1/2">
                <select
                  className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200"
                  onChange={(e) => setPembayaran(e.target.value)}
                >
                  <option value="tunai">Tunai</option>
                  <option value="bsi">BSI</option>
                </select>
              </div>
            </div>
            <div className="w-1/2"></div>
          </div>
          <div className="flex flex-col mb-4">
            <label className="font-medium mb-2">Nominal</label>
            <div className="w-1/2">
              <input
                value={rupiahDisplay || ""}
                onChange={rupiah}
                className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200"
              />
              {statusError && (
                <p className="pt-2 text-red-500">Harus diisi bapack....</p>
              )}
              <button
                className="mt-4 pt-2 pb-2 pr-3 pl-3 rounded-lg bg-blue-500 hover:bg-blue-700 text-lg text-white "
                onClick={save}
              >
                SIMPAN
              </button>
            </div>
          </div>
        </div>
      </Card>
   
  );
}

export default TabunganProses;
