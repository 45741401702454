import React, { useEffect } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Card from "../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { getReport, getUser } from "../store/actions/report";
import Table from "../components/Table";
import { formatRupiah, timeShow, timeToUtc } from "../components/util";
import { reportAction } from "../store/slice/report";



const schema = yup.object({
  start: yup.date().required().typeError("harus diisi"),
  end: yup.date().required().typeError("harus diisi"),
  username: yup.string().required("harus diisi"),
});

const head = [
  {title:"No"},
  {title:"Tanggal"},
  {title:"No Rek"},
  {title:"Nama"},
  {title:"Jumlah"},
  {title:"Status"}

]

function Report() {
  const { statusLoading, dataReport, userReport } = useSelector((state) => state.report);
  const {user} = useSelector((state)=>state.user)
  const dispatch = useDispatch();
  const methods = useForm({ resolver: yupResolver(schema) });
  useEffect(()=>{
    dispatch(getUser())
    dispatch(reportAction.clearDataReport())
    console.log(user)
  },[dispatch, user])
  const proses = async (data) => {
    dispatch(
      getReport({
        start: timeToUtc(data.start, true),
        end: timeToUtc(data.end, false),
        username: data.username,
      })
    );
    
  };
  return (
    <Card title="Laporan">
      {/* {timeToUtc(`${data.start}`)} */}
      <form onSubmit={methods.handleSubmit(proses)}>
        <div className="flex flex-col md:flex-row flex-wrap md:space-x-1 space-y-1 justify-end items-center">
          <div className="flex flex-col w-full md:w-1/4">
            <label className="font-medium m-1">Dari Tanggal</label>
            <input
              type="date"
              className="px-3 py-3  w-full  border border-gray-300 rounded-lg outline-none"
              placeholder="dari tanggal"
              {...methods.register("start")}
            />
          </div>
          <div className="flex flex-col w-full md:w-1/4">
            <label className="font-medium m-1">Sampai Tanggal</label>
            <input
              type="date"
              className="px-3 py-3 w-full  border border-gray-300 rounded-lg outline-none"
              placeholder="sampai tanggal"
              {...methods.register("end")}
            />
          </div>
          <div className="flex flex-col w-full md:w-1/4">
            <label className="font-medium m-1">User</label>
            <select
              {...methods.register("username")}
              className="px-3 py-3 w-full border border-gray-300 rounded-lg outline-none"
            >
             {
              user.admin ? userReport.map((d, i)=>(
                <option value={d.username} key={i}>{d.nama}</option>
               )):[{username:user.username, nama:user.nama}].map((d, i)=>(
                <option value={d.username} key={i}>{d.nama}</option>
               ))
             }
            </select>
          </div>
          <div className="flex flex-col flex-wrap items-center md:pt-7 w-full md:w-20">
          

          <button
            className="rounded full px-3 py-3 md:w-20 w-full bg-blue-600 hover:bg-blue-700 text-white"
            disabled={statusLoading}
          >
            Cari
          </button>
          </div>
          
        </div>
      </form>
      <div className="mt-10">
        {
          dataReport.length!==0?
        <Table head={head}>
          {dataReport.map((d,i)=>(             
          <tr className="bg-white border-b hover:bg-gray-50" key={i}>
             <td className="py-4 px-6">{i+1}</td>
             <td className="py-4 px-6">{timeShow(d.createdAt)}</td>
             <td className="py-4 px-6">{d.noRek}</td>
             <td className="py-4 px-6">{d.nama.toUpperCase()}</td>
             <td className="py-4 px-6 text-right">{formatRupiah(d.jumlah)}</td>
             <td className="py-4 px-6">{d.status}</td>
          </tr>
             
          ))}
        </Table>:''
}
      </div>
    </Card>
  );
}

export default Report;
