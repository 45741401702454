import React from "react";

function TextField(props) {
  return (
    <div className="flex flex-col mb-4">
      <label className="font-medium mb-2">{props.label}</label>
      <div className={`bg-red ${props.width}`}>
        <input type={props.typeField} className={`px-3 py-3 w-full border border-gray-300 rounded-lg outline-none ${props.methods.formState.errors[props.name_] ? 'focus:border-red-500':"focus:border-pink-200"}`} {...props.methods.register(props.name_)}  disabled={props.disabled_||false}/>
        <p className="p-1 text-red-500">{props.methods.formState.errors[props.name_]?.message}</p>
      </div>
    </div>
  );
}

export default TextField;
