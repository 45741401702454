import React from "react";

function Radio(props) {


  return (
    <div>
      <label className="font-medium mb-2">{props.title}</label>
      <div className="flex lex-row ">
        {props.deco.map((d, i) => (
          <div className="flex flex-row mr-2" key={i}>
            <input type="radio" {...props.methods.register(props.name_)} value={d.value}/>
            <label className="font-medium p-1">{d.label}</label>
          </div>
        ))} 
      </div>
      <p className="p-1 text-red-500">{props.methods.formState.errors[props.name_]?.message}</p>
    </div>
  );
}

export default Radio;
