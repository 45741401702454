import React, { useEffect, useState } from "react";
import Card from "../components/Card";
import CardInfo from "../components/CardInfo";
// import ArrowSolid from "../components/icon/ArrowSolid";
import UserGroup from "../components/icon/UserGroup";
import Money from "../components/icon/Money";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "../store/actions/dashboard";
// import { dashboardAction } from "../store/slice/dashboard";
import {formatRupiah} from '../components/util'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Proyeksi Pemasukan dan Pengeluaran Sealama 1 Bulan",
    },
  },
};

// const labels = [
//   1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
//   23, 24, 25, 26, 27, 28, 29, 30, 31,
// ];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Pemasukan",
//       data: [
//         12,
//       ],
//       borderColor: "rgb(30,144,255)",
//       backgroundColor: "rgb(30,144,255)",
//     },
//     {
//       label: "Pengeluaran",
//       data: [900, 0, 7, 4, 8, 1, 800],
//       borderColor: "rgb(220,20,60)",
//       backgroundColor: "rgb(220,20,60)",
//     },
//   ],
// };

function Dashboard() {
  const {loading, data, tableGenerate} = useSelector((state)=> state.dashboard)
  const dispatch = useDispatch()
  const [labels, setLabels] = useState([])
  const [canShow , setCanShow] = useState(false)
  useEffect(()=>{
   if(!loading){
    const timer = setTimeout( () => setCanShow(true) , 300)
    return () => clearTimeout(timer);
   }
  })
  const [dataTable, setDataTable] = useState({
    labels,
    datasets:[
       {
        label:"Tabung",
        data:[],
        borderColor: "rgb(30,144,255)",
        backgroundColor: "rgb(30,144,255)",
       },{
        label:"Tarik",
        data:[],
        borderColor: "rgb(220,20,60)",
        backgroundColor: "rgb(220,20,60)",
       }
    ]
})
//   useEffect(()=>{
//     dispatch(dashboardAction.updateTableStatus())
//     console.log(dataTable)
//   },[dataTable, dispatch])
  useEffect(()=>{
    setLabels(data.pendapatan.date)
    setDataTable({
        labels,
        datasets:[
           {
            label:"Tabung",
            data:data.pendapatan.tabung,
            borderColor: "rgb(30,144,255)",
            backgroundColor: "rgb(30,144,255)",
           },{
            label:"Tarik",
            data:data.pendapatan.tarik,
            borderColor: "rgb(220,20,60)",
            backgroundColor: "rgb(220,20,60)",
           }
        ]
    })
    
  },[data, labels])

  useEffect(()=>{
    dispatch(getDashboard())
    // dispatch(dashboardAction.clearState())
    
  }, [dispatch])
  return (
    <>
      <div className="mt-8 grid gap-10 lg:grid-cols-2 sm-grid-cols-2 p-4">
        <CardInfo title="Saldo Keseluruhan" info={loading ? 'Loading...':formatRupiah(data.totalTabungan)} icon={<Money />} />
        <CardInfo title="Total Nasabah" info={loading ?  'Loading...':data.totalNasabah} icon={<UserGroup />} />
      </div>
      <div className="mt-8 p-4">
        <div className="flex items-center bg-white rounded shadow-sm justify-between p-5">
            {canShow ? <Line options={options} data={dataTable} width={100} height={30} />:'Loading...'}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
