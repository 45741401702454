import axios from "axios";
import { userAction } from "../store/slice/user";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const axiosApi = axios.create({
  // baseURL: "http://localhost:8589",
  // baseURL:"http://10.10.10.37:8589"
  baseURL:'https://api-tpsu.ponpesabuhurairah.id'
});

axiosApi.interceptors.request.use(
  
  (config) => {
    if (config.url !== "/login") {
      config.headers.Authorization = `Bearer ${
        store.getState().user.user.token
      }`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosApi.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 || err.response.status === 403) {
      store.dispatch(userAction.clearData());
    }
    return Promise.reject(err)
  }
);

export default axiosApi;
