import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../store/slice/user";
import { userLogout } from "../store/actions/user";
import Close from "./icon/Close";
import Users from "./icon/Users";
import User from "./icon/User";
import Key from "./icon/Key";
import { useNavigate } from "react-router-dom";

// import DropdownComponent from "./Dropdown";
function Navbar(props) {
  const [status, setStatus] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const navigate = useNavigate();
  const menu = () => {
    setStatus(true);
  };

  const logout = async () => {
    setStatus(false);
    await dispatch(userLogout());
    await dispatch(userAction.clearData());
  };
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setStatus(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.addEventListener("click", handleClickOutside, true);
    };
  }, [status]);

  const toUser = () => {
    setStatus(false);
    navigate("/users");
  };
  const newPassword = ()=>{
    props.showPasswordDialog()
    setStatus(false)
  }
  return (
    <nav className="bg-slate-500 border-gray-200 px-4 sm:px-4 py-2.5">
      <div className="flex flex-row flex-wrap justify-between items-center">
        <div onClick={props.show}>
          {props.status ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white transition-all delay-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
              />
            </svg>
          )}
        </div>
        <div>
          <button onClick={menu}>
            <div className="flex flex-row">
              <div className="text-white font-sans font-medium mr-1">
                {user.nama.toUpperCase()}
              </div>
              <User layout="w-6 h-6 text-white" />
            </div>
          </button>
          <div
            className={`fixed flex flex-col bg-white p-3 rounded ${
              status ? "visible top-90 left-100 right-10" : "invisible"
            }`}
            ref={ref}
          >
            {user.admin ? (
              <button
                onClick={toUser}
                className="px-2 py-1 flex flex-row justify-between items-center"
              >
                <Users layout={"w-5 h-5 mr-1"} />
                List User
              </button>
            ) : (
              ""
            )}
            <button
              onClick={newPassword}
              className="px-2 py-1 flex flex-row justify-between items-center"
            >
            
                <Key layout="w-5 h-5 mr-1" />
 
              
                Perbaharui Password
            </button>
            <button
              onClick={logout}
              className="px-2 py-1 flex justify-between items-center"
            >
             
                <Close layout="w-5 h-5 mr-1" />
             
             Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
