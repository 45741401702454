import React from "react";

function Card(props) {
  return (
    <div className="flex flex-col bg-white m-5 rounded-bl-md rounded-br-md">
      <div className="bg-gray-600 p-5 text-white rounded-tl-md rounded-tr-md"><b>{props.title}</b></div>
      <div className="p-5">{props.children}</div>
    </div>
  );
}

export default Card;
