import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Interfaces from "./ui/Interfaces";
import Register from "./ui/Register";
import Search from "./ui/Search";
import Tabungan from "./ui/Tabungan";
import Log from "./ui/Log";
import React from "react";
import Login from "./ui/Login";
import EditNasabah from "./ui/EditNasabah";
import { useSelector } from "react-redux";
import Dashboard from "./ui/Dashboard";
import Cetak from "./ui/Cetak";
import Report from "./ui/Report";
import Users from "./ui/Users";

function App() {
  const [dialog_, setDialog_] = useState(false);
  const [dialogPassword, setDialogPassword] = useState(false)

  const hiddenPasswordDialog = ()=>{
    setDialogPassword(false)
  }

  const showPasswordDialog = ()=>{
    setDialogPassword(true)
  }

  const hiddenDialog = () => {
    setDialog_(false);
  };
  const showDialog = ()=>{
    setDialog_(true)
  }
 
  const {user} = useSelector((state)=>state.user)
  return (
   
      <Routes>
      
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/" element={
          user.token!==undefined ? <Interfaces dialogPassword={dialogPassword} hiddenPasswordDialog={hiddenPasswordDialog} showPasswordDialog={showPasswordDialog} />:<Navigate to="/login"/>
        }>
          <Route path="register" element={<Register dialog={dialog_} hiddenDialog={hiddenDialog} showDialog={showDialog}/>} />
          <Route path="search" element={<Search dialog={dialog_} hiddenDialog={hiddenDialog} showDialog={showDialog}/>} />
          <Route path="tabungan/:uuid" element={<Tabungan dialog={dialog_} hiddenDialog={hiddenDialog} showDialog={showDialog}/>} />
          <Route path='nasabah/edit/:uuid' element={<EditNasabah/>}/>
          <Route path="nasabah/log/:uuid" element={<Log />} />
          <Route path="dashboard" element={<Dashboard/>}/>
          <Route path="Report" element={<Report/>}/>
          <Route path="/users" element={user.admin ? <Users dialog={dialog_} hiddenDialog={hiddenDialog} showDialog={showDialog}/>:<Navigate to='/dashboard'/>}/>
        </Route>
        <Route path="/cetak/:uuid" element={user.token!==undefined ? <Cetak />:<Navigate to="/login"/>}/>
        <Route path="/login" element={user.token!==undefined ? <Navigate to='/dashboard'/>:<Login/>} />
      </Routes>
      
    
  );
}

export default App;
