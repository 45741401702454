import { createSlice } from "@reduxjs/toolkit";
import { userLogin, userLogout, getUserList, generateUser, updateAdmin, updatePassword_ } from "../actions/user";

const userSlice = createSlice({
    name:'user',
    initialState:{
        user:{},
        listUser:[],
        userStatus:'waiting',
        errorUpdate:{statusPwd:true, statusCompare:true, msg:'password compare wrong'}
    },
    reducers:{
        updateStatus(state){
            state.userStatus='waiting'
        },
        clearData(state){
            state.userStatus='waiting'
            state.user={}
        },
        updateData(state){
            state.listUser = []
        }
    },
    extraReducers:builder=>{
        builder
        .addCase(userLogin.pending, (state)=>{
            state.user = {}
            state.userStatus='loading'
        })
        .addCase(userLogin.rejected,(state)=>{
            state.userStatus='error'
        })
        .addCase(userLogin.fulfilled, (state, action)=>{
            state.userStatus='success'
            state.user = action.payload 
        })
        .addCase(userLogout.pending,(state)=>{
            state.userStatus='loading'
        })
        .addCase(userLogout.rejected, (state)=>{
            state.userStatus='error'
        })
        .addCase(userLogout.fulfilled, (state)=>{
            state.userStatus='success'
        }).addCase(getUserList.pending,(state)=>{
            state.userStatus='loading'
        }).addCase(getUserList.fulfilled,(state, action)=>{
            state.userStatus='success'
            state.listUser = action.payload
        }).addCase(getUserList.rejected, (state)=>{
            state.userStatus='error'
        }).addCase(generateUser.pending, (state)=>{
            state.userStatus = 'waiting'
        }).addCase(generateUser.fulfilled,(state)=>{
            state.userStatus = 'success'
        }).addCase(generateUser.rejected, (state)=>{
            state.userStatus='error'
        }).addCase(updateAdmin.pending,(state)=>{
            state.userStatus='waiting...'
        }).addCase(updateAdmin.fulfilled, (state)=>{
            state.userStatus='success'
        }).addCase(updateAdmin.rejected, (state)=>{
            state.userStatus='error'
        }).addCase(updatePassword_.pending, (state)=>{
            state.userStatus='waiting...'
        }).addCase(updatePassword_.fulfilled, (state, action)=>{
            console.log(action)
            // state.errorUpdate=action.payload
            state.userStatus='success'
        }).addCase(updatePassword_.rejected,(state, action)=>{
            state.userStatus='error'
            // console.log(action.payload)
            state.errorUpdate = action.payload
        })

    }
})

export const userAction = userSlice.actions
export default userSlice