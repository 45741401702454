import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Sidebar from '../components/Sidebar'
import UpdatePassword from "../components/UpdatePassword";


function Interfaces(props) {
  const [status, setStatus] = useState(true);

  const show = () => {
    setStatus(!status);
  };
  
  return (
    <div className="flex z-10 flex-row overflow-x-hidden overflow-y-hidden">
      
      <Sidebar status={status} />
      <div className="bg-blue-50 w-full min-h-screen">
        <div className="flex flex-col">
          <div className="flex flex-col bg-white">
            <Navbar status={status} show={show} showPasswordDialog={props.showPasswordDialog}/>
            <UpdatePassword dialog={props.dialogPassword} hiddenPasswordDialog={props.hiddenPasswordDialog}/>
          </div>
          <div className="flex flex-col">
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interfaces;
