import React from "react";

function CardInfo(props) {
  return (
    <div className="flex items-center bg-white rounded shadow-sm justify-between p-5">
      <div className="text-sm flex flex-row flex-wrap items-center">

        <div className="text-pink-500">{props.icon}</div>
        <div className="ml-1 font-bold">{props.title}</div>
      </div>
      <div className="flex items-center pt-1">
        <div className="text-3xl font-medium text-gray-600">{props.info}</div>
      </div>
    </div>
  );
}

export default CardInfo;
