import { nasabah, generateNasabah } from "../../api/tabungan";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {search, nasabahGetId, updateNasabah as nasabahUpdate} from '../../api/nasabah'

export const getNasabah = createAsyncThunk(
    "nasabah/getNasabah",
    async(_, {rejectWithValue})=>{
        try{
            const res = await nasabah()
            if(res.status===200){
                return res.data
        }
        }catch(e){
            return rejectWithValue('uups something wrong')
        }
    }
)

export const createNasabah = createAsyncThunk(
    'nasabah/createNasabah',
    async(data, {rejectWithValue})=>{
        try{
            const res = await generateNasabah(data)
            if(res.status===200){
                return res.data
            }
        }catch(e){

            return rejectWithValue('uuups something wrong')
        }
    }
)

export const updateNasabah = createAsyncThunk(
    'nasabah/updateNasabah',
    async(data, {rejectWithValue})=>{
        try{
            await nasabahUpdate(data.uuid, data.data)
        }catch(e){
            console.log(e)
            return rejectWithValue('uuups')
        }
    }
)

export const searchNasabah = createAsyncThunk(
    'nasabah/serachNasabah',
    async(key, {rejectWithValue})=>{
        try{
            const res = await search(key)
            if(res.status===200){
                return res.data
            }
        }catch(e){
            return rejectWithValue('uuups something wrong')
        }
    }
)

export const getNasabahId = createAsyncThunk(
    'nasabah/getNasabahId',
    async (uuid, {rejectWithValue})=>{
        try{
            const res = await nasabahGetId(uuid)
            if(res.status===200){
                return res.data
            }
        }catch(e){
            return rejectWithValue('uuups something wrong')
        }
    }
)