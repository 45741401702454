import React, { useEffect } from "react";
import ReactToPrint from "react-to-print";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTabunganId } from "../store/actions/transaksi";
import { timeShow } from "../components/util";
import { formatRupiah } from "../components/util";
function Cetak() {
  const { data } = useSelector((state) => state.tabungan);
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getTabunganId(uuid));
  }, [dispatch, uuid]);

  return (
    <div>
      <ReactToPrint
        trigger={() => {
          return (
            <button className="p-2 bg-blue-500 text-white m-2">Cetak</button>
          );
        }}
        content={() => componentRef.current}
      />
      <button
        className="p-2 bg-green-500 text-white m-2"
        onClick={() => navigate("/")}
      >
        Kembali
      </button>
      <div ref={componentRef}>
        <div className="w-1/4 print:w-full">
          <div className="grid grid-cols-2 print:grid print:grid-cols-2 print:m-3">
            <div>No Rek</div>
            <div className="text-right">{data.noRek}</div>
            <div>Nama</div>
            <div className="text-right">{data.nama.toUpperCase()}</div>
          </div>
          <div className="print:mx-3">
            <hr className="my-3 bg-black border-0 h-1"></hr>
            <table className="table-auto w-full ">
              <thead>
                <tr>
                  <th className="text-left">Tanggal</th>
                  <th className="text-left">Ref</th>
                  <th className="text-left">Jumlah</th>
                  <th className="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {data.tabungans.map((d, i) => (
                  <tr key={i}>
                    <td>{timeShow(d.updatedAt)}</td>
                    <td>{d.ref}</td>
                    <td>{formatRupiah(d.jumlah)}</td>
                    <td>{d.status}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3} className="text-center">
                    Umroh
                  </td>
                  <td>
                    {formatRupiah(
                      data.totalTabungans[
                        data.totalTabungans.findIndex((p) => p.type === "umr")
                      ].total
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="text-center">
                    Korban
                  </td>
                  <td>
                    {formatRupiah(
                      data.totalTabungans[
                        data.totalTabungans.findIndex((p) => p.type === "kbr")
                      ].total
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="text-center">
                    Umum
                  </td>
                  <td>
                  {formatRupiah(
                    data.totalTabungans[
                      data.totalTabungans.findIndex((p) => p.type === "umm")
                    ].total
                  )}
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} className="text-center">
                    Total
                  </td>
                  <td>
                    {formatRupiah(
                      data.totalTabungans[0].total +
                        data.totalTabungans[1].total +
                        data.totalTabungans[2].total
                    )}
                  </td>
                </tr>
                
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cetak;

// class Cetak extends React.PureComponent {

//   render() {
//     return (
//       <div>
//         <ReactToPrint
//           trigger={() => {
//             return (
//               <button className="p-2 bg-blue-500 text-white m-2">Cetak</button>
//             );
//           }}
//           content={() => this.componentRef}
//         />
//         <button className="p-2 bg-green-500 text-white m-2" onClick={()=>this.props.history.push('/')}>Kembali</button>
//         <div ref={(el) => (this.componentRef = el)}>
//           <div className="w-1/4 print:w-full">
//             <div className="grid grid-cols-2 print:grid print:grid-cols-2 print:m-3">
//               <div>No Rek</div>
//               <div className="text-right">2122122</div>
//               <div>Nama</div>
//               <div className="text-right">Jayadi Rahmat</div>
//             </div>
//             <div className="print:mx-3">
//               <hr className="my-3 bg-black border-0 h-1"></hr>
//               <table className="table-auto w-full ">
//                 <thead>
//                   <tr>
//                     <th>Tanggal</th>
//                     <th>Ref</th>
//                     <th>Jumlah</th>
//                     <th>Status</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>2022-01-02</td>
//                     <td>IN-00001</td>
//                     <td>500.000</td>
//                     <td className="text-right">K</td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
