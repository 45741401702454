import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import TextField from './TextField'
import { useDispatch, useSelector } from 'react-redux'
import {updatePassword_} from '../store/actions/user'
import { userAction } from '../store/slice/user'


const schema = yup.object({
    password: yup.string().required('tidak boleh kosong').min(8, 'min 8 karakter'),
    newPassword:yup.string().required('tidak boleh kosong').min(8, 'min 8 karakter'),
    confrimPassword:yup.string().required('tidak boleh kosong').oneOf([yup.ref('newPassword'), null], 'password harus sama!').required('tidak boleh kosong')
})

function UpdatePassword({dialog, hiddenPasswordDialog}) {
  
  const {userStatus,errorUpdate } = useSelector((state)=>state.user)
  const dispatch = useDispatch()

  const methods = useForm({
    resolver:yupResolver(schema)
  })

  

  

  const hiddenPassword = ()=>{
    methods.reset()
    methods.clearErrors()
    hiddenPasswordDialog()
    dispatch(userAction.updateStatus())

  }
  const prosesUpdate = async (data)=>{
   await dispatch(updatePassword_(data))
  }


  useEffect(()=>{
    if(userStatus==='success'){
        dispatch(userAction.updateStatus())
        methods.reset()
        methods.clearErrors()
        hiddenPasswordDialog()
    }
  },[userStatus, dispatch, hiddenPasswordDialog, methods])


  return (
    <div className={`${dialog ? "" : "hidden"}`}>
        
      <div className="justify-center items-center flex overflow-x-hidden z-50 overflow-y-auto fixed inset-0 outline-none focus:outline-none">
        <div className="relative md:w-1/2 w-full my-6">
            <div className="flex flex-col bg-white p-5 px-10 rounded">
            <div className="py-2 font-bold text-xl mb-3">
                Update Password
            </div>
            {userStatus==='error' && <p className='text-red-500'>password anda salah!</p>}
                <form onSubmit={methods.handleSubmit(prosesUpdate)} onReset={hiddenPassword}>
                    <TextField typeField="password" methods={methods} label="Password Lama" width="w-full" name_="password"/>
                    <TextField typeField="password" methods={methods} label="Password Baru" width="w-full" name_="newPassword"/>
                    <TextField typeField="password" methods={methods} label="Confrim Password Baru" width="w-full" name_="confrimPassword"/>
                    <div className="flex flex-row justify-end space-x-2">
                        <button className='bg-green-500 text-white p-2 rounded' type='submit'>Simpan</button>
                        <button className='bg-red-500 text-white p-2 rounded' type='reset'>Batal</button>
                    </div>
                
                </form>
            </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black" ></div>
    </div>
  )
}

export default UpdatePassword