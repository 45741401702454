import React from 'react'

function TextFieldV(props) {
  return (
    <div className='flex flex-col mb-4'>
        <label className='font-medium mb-2'>{props.label}</label>
        <div className={props.width}>
            <input type={props.typeField} value={props.val} className='px-3 py-3 w-full border border-gray-300 rounded-lg outline-none focus:border-pink-200' disabled={props.disable===true ? true:false}/>
        </div>

    </div>
  )
}

export default TextFieldV